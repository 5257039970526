import React, { useEffect } from 'react';
import { Grid, Container, Card, CardContent, Button, Avatar, Typography, Box} from '@mui/material';
import { useMediaQuery, useTheme } from '@mui/material';
import Layout from '../layouts/Layout';
import circle from '../img/newhome/circle.png';
import peaple from '../img/newhome/peaple.png';
import location from '../img/newhome/location.png';
import web from '../img/newhome/web.png';
import cloud from '../img/newhome/cloud.png';
import backup from '../img/newhome/backup.png';
import support from '../img/newhome/support.png';
import tools from '../img/newhome/tools.png';
import download from '../img/newhome/download.png';
import tech4DevLogo from '../img/newhome/techdev.png';
import hondurasDigitalChallengeLogo from '../img/newhome/digitalchallenge.png';
import emprendeRocketLogo from '../img/newhome/emprenderocket.png';
import landingRocket from '../img/newhome/landingrocket.png';
import HUB504 from '../img/newhome/HUB504.png';
import BID_lab from '../img/newhome/BID_lab.png';
import Logo_financiamiento from '../img/newhome/Logo_financiamiento.png';
// import USAID_TMS from '../img/newhome/USAID_TMS.png';
import Fundacion_startup from '../img/newhome/Fundacion_startup.png';
import Reservando from '../img/newhome/Reservando.png';

/* import platform from '../img/newhome/platform.png'; */
import { pricedeploy } from '../textdata/priceDeploy';
import avatar from '../img/newhome/monica.png';
import ConsultSection from '../components/ConsultSection';
import iconDB from '../img/consult_export/DataBackup.png';
import iconEndpoint from '../img/consult_export/Connecting.png';
import iconPages from '../img/consult_export/TermsConditions.png';
import iconUsers from '../img/consult_export/Security.png';
import CardExportCode from '../components/CardExportCode/CardExportCode';
import AOS from 'aos';
import 'aos/dist/aos.css';



const listOptions = [
  {
    img: tools,
    title: 'Fácil Configuración',
    description: 'Olvídate de la infraestructura, olvídate del código, enfócate en lo que de verdad le es util a ti o y tu negocio'
  },
  {
    img: download,
    title: 'Descarga Rápida',
    description: 'Crea aplicaciones sin código. Diseño atractivo, integraciones fáciles y despliegue rápido. ¡Haz realidad tus ideas digitales en minutos!'
  },
  {
    img: support,
    title: 'Mejor Soporte',
    description: '¿Necesitas ayuda? Nuestro equipo de soporte está listo para asistirte en cada paso. ¡Cuenta con nosotros para hacer brillar tu creatividad en Lofty Apps!'
  },
]

const listClients = [
  {
    name: 'Francisco Morazan',
    position: 'CEO Estudio Lukanka',
    img: 'https://argesware.com/assets/images/testimonial/francisco.jpg',
    description: 'Como emprendedor me ha sido de mucha ayuda los servicios que me brindan y las excelentes asesorías técnicas que ofrecen. Permitiéndome enfocarme en experimentar, iterar y lanzar proyectos que resuelven problemas reales en menos tiempo.'
  },
  {
    name: 'Monica Mártinez',
    position: 'CEO Taller Roma',
    img: avatar,
    description: 'Con la ayuda y asesoramiento de Lofty mi negocio ahora puede obtener una ventaja competitiva al crear una página web que eleve el alcance y el prestigio de mi emprendimiento generando confianza en los productos y servicios que ofrecemos. Nunca imaginé que una plataforma fuera tan fácil de utilizar y sin necesidad de experiencia en programación o páginas web, ofreciendo herramientas para que cualquier negocio pueda tener éxito con un diseño creativo y exclusivo logrando un posicionamiento online.'
  }
]

const listExportCode = [
  {
    name: 'Colecciones de Datos',
    price: 'HNL 250.00',
    iconImg: iconDB,
  },
  {
    name: 'Servicios (Endpoint)',
    price: 'HNL 125.00',
    iconImg: iconEndpoint,
  },
  {
    name: 'Paginas',
    price: 'HNL 250.00',
    iconImg: iconPages,
  },
  {
    name: 'Sistema de Autenticación',
    price: 'HNL 325.00',
    iconImg: iconUsers,
  },
]

const NewHome = ({history}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('lg'));
  
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <Layout>
      <header>
        <section className='section-loftyapps'>
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <Grid container>
              <Grid style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} item lg={6} md={6} xs={12} >
                <div>
                  <div className="imgcircule-loftyapps" style={{backgroundImage: `url(${circle})`}}>
                    <img className='imgpeaple-loftyapps' alt='Peaple' src={peaple} />
                    {/* Imagenes animadas */}
                    <img className='img-web-animation-loftyapp' alt='web' src={web} />
                    <img className='img-location-animation-loftyapp' alt='location' src={location} />
                    <img className='img-cloud-animation-loftyapp' alt='cloud' src={cloud} />
                    <img className='img-backup-animation-loftyapp' alt='backup' src={backup} />
                  </div>
                </div>
              </Grid>
              <Grid item style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}} lg={6} md={6} xs={12} >
                <div>
                  <h1 className='title1-loftyapps title-landing-loftyapps' style={{textAlign: 'right', margin: 0}}>
                    Construya productos digitales
                  </h1>
                  <h1 className='title2-loftyapps subtitle-landing-loftyapps content-list-animation-loftyapps' style={{textAlign: 'right', margin: 0}}>
                    <ul className='container-list-text-animation-loftyapps'>
                      <li className='item-text-animation-loftyapps'>
                        Marketplace
                      </li>
                      <li className='item-text-animation-loftyapps'>
                        Sitios Web
                      </li>
                      <li className='item-text-animation-loftyapps'>
                        Landing Page
                      </li>
                      <li className='item-text-animation-loftyapps'>
                        Aplicaciones Web
                      </li>
                    </ul>
                  </h1>
                  <h2 className='title3-loftyapps text-landing-loftyapps' style={{textAlign: 'right', margin: 0}}>
                    Todo esto y más lo puedes hacer con LoftyApps, sin necesidad de una línea de código
                  </h2>
                  <div className='container-button-loftyapps'>
                    <Button
                      onClick={() => {
                        window.location.href = 'https://platform.loftyapps.com'
                      }}
                      variant='contained'
                    >
                      Ingresar
                    </Button>
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </header>
      <main>
        <section style={{display: 'flex', alignItems: 'center'}} className='section-gray-loftyapps'>
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <Grid spacing={6} container>
              <Grid item lg={3} md={12} xs={12} sm={12} data-aos="fade-up" data-aos-duration="1000">
                <h2 style={{margin: 0, textAlign: 'center'}} className='title1-loftyapps' >
                  ¿Por qué elegirnos?
                </h2>
                <p style={{margin: 0, textAlign: 'center'}} className='title3-loftyapps'>
                  Ventajas de trabajar con nosotros
                </p>
              </Grid>
              {
                listOptions.map((item) => (
                  <Grid key={item.title} item lg={3} md={4} xs={12} data-aos="fade-up" data-aos-duration="1000">
                    <div className='container-advantage-loftyapps'>
                      <img alt='Ventajas' src={item.img} />
                    </div>
                    <h3 className='title3-loftyapps'>
                      {item.title}
                    </h3>
                    <p className='text-loftyapps'>
                      {item.description}
                    </p>
                  </Grid>
                ))
              }
            </Grid>
          </Container>
        </section>
        <section className='section-white-loftyapps' >
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <h2 style={{textAlign: 'center'}} className='title1-loftyapps' data-aos="fade-up" data-aos-duration="1000">
              Una Visión Al Futuro
            </h2>
            <Card className='card-loftyapps' data-aos="fade-up" data-aos-duration="1000">
              <CardContent style={{textAlign: 'center', paddingLeft: '10%', paddingRight: '10%'}}>
                {/* <img style={{margin: 'auto', width: '75%'}} alt='Plataform Lofty Apps' src={platform} /> */}
                <iframe
                  className='video-youtube'
                  src="https://www.youtube.com/embed/D54ykR-QcjQ?si=TehDPYrUsfX1c60k"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                />
                <p className='text-loftyapps' style={{margin: 'auto', width: '65%', marginTop: 25}}>
                ¡Empieza a hacer realidad tus sueños digitales hoy mismo con Lofty Apps!
                No pierdas más tiempo en aprender lenguajes de programación complicados.
                Con nuestra plataforma, la creación de aplicaciones es accesible para todos.
                Regístrate ahora y descubre cómo Lofty Apps puede transformar tus ideas en realidad.
                </p>
              </CardContent>
            </Card>
          </Container>
        </section>
        <section className='section-white-loftyapps' >
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <div className='padding-container-loftyapps' style={{background: '#29B2EF', height: '2px', marginTop: '10px', marginBottom: '75px'}} />
            <h2 style={{textAlign: 'center'}} className='title1-loftyapps' data-aos="fade-up" data-aos-duration="1000">
              Confían en Nosotros
            </h2>
            <Grid spacing={12} container>
              <Grid item lg={12} md={12} xs={12} sm={12} data-aos="fade-up" data-aos-duration="1000">
                <p style={{margin: 0, textAlign: 'center', padding: '0 20%'}} className='text-loftyapps' data-aos="fade-up" data-aos-duration="1000">
                  Empresas líderes y miles de clientes satisfechos han depositado su confianza en nosotros para llevar sus proyectos al siguiente nivel.
                </p>
              </Grid>
              <Grid item lg={4} md={6} xs={6} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <img
                  src={HUB504}
                  alt="HUB 504 Fedecamara Logo"
                  style={{
                    maxWidth: '90%',
                    maxHeight: '90%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={6} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <img
                  src={Logo_financiamiento}
                  alt="Financiado por la Union europea Logo"
                  style={{
                    maxWidth: '90%',
                    maxHeight: '90%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
              <Grid item lg={4} md={6} xs={6} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <img
                  src={BID_lab}
                  alt="BID Lab Logo"
                  style={{
                    maxWidth: '90%',
                    maxHeight: '90%',
                    objectFit: 'contain',
                    paddingTop: '20px',
                  }}
                />
              </Grid>

              <Grid item lg={3} md={6} xs={6} sm={6} data-aos="fade-up" data-aos-duration="1000"
                style={{
                  marginLeft: isMedium ? '0' : '25%',
                  paddingBottom: '80px',
                }}
              >
                <img
                  src={Fundacion_startup}
                  alt="Fundacion Startup Logo"
                  style={{
                    maxWidth: '90%',
                    maxHeight: '90%',
                    objectFit: 'contain',
                  }}
                />
              </Grid>
              <Grid item lg={3} md={6} xs={6} sm={6} data-aos="fade-up" data-aos-duration="1000">
                <img
                  src={Reservando}
                  alt="Reservando Logo"
                  style={{
                    maxWidth: '90%',
                    maxHeight: '90%',
                    objectFit: 'contain',
                    paddingTop: '20px',
                  }}
                />
              </Grid>
            </Grid>
            <div className='padding-container-loftyapps' style={{background: '#29B2EF', height: '2px', marginTop: '10px', marginBottom: '75px'}} />
          </Container>
        </section>
        <section className='section-gray-loftyapps'>
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <h2 style={{textAlign: 'center'}} className='title1-loftyapps' data-aos="fade-up" data-aos-duration="1000">
              Suscripciones De Cuentas
            </h2>
            <Grid spacing={2} container>
              <Grid item lg={4} md={12} xs={12} style={{height: 'px', display: 'fixed'}} data-aos="fade-up" data-aos-duration="1000">
              <Card className='card-blue-loftyapps card-enterprice-loftyapps' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <CardContent style={{ width: '75%' }}>
                    <h3 style={{fontSize: 24, fontWeight: 600, margin: 0}} className='text-white' data-aos="fade-up">
                      Plan de Acompañamiento y Enterprice
                    </h3>
                    <h4 style={{margin: 0}} className='title2-loftyapps'>
                      Contáctanos
                    </h4>
                    <p style={{margin: 0}} className="text-loftyapps text-white">
                      Con nuestro Plan de Acompañamiento tu negocio nuna estara solo
                    </p>
                    <Button onClick={() => history.push('/prices')} style={{marginTop: 15}} variant='contained' className='text-white' >
                      Ver Info
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item lg={8} md={12} >
                <Grid spacing={2} container data-aos="fade-up" data-aos-duration="1000">
                  {
                    pricedeploy.map((item) => (
                      <Grid key={item.type} item md={6} xs={12}>
                        <Card className='card-blue-outline-loftyapps'>
                          <CardContent>
                            <h2 className='title3-loftyapps' style={{fontSize: 24}} >
                              {item.type}
                            </h2>
                            <h2 style={{fontSize: 25, fontWeight: 700, color: '#14569e'}}>
                              {`HNL ${item.priceYear.toLocaleString()} Anual`}
                            </h2>
                            <p className='text-loftyapps' style={{fontSize: 18, fontWeight: 500}}>
                              {`Colaboradores ${item.collabs}`}
                            </p>
                            <p className='text-loftyapps' style={{fontSize: 18, fontWeight: 500}}>
                              {`Almacenamiento ${item.storage}`}
                            </p>
                            <div>
                              <Button onClick={() => history.push('/prices')} variant='outlined'>
                                Ver Info
                              </Button>
                            </div>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))
                  }
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section style={{display: 'flex', alignItems: 'center', paddingTop: '100px', paddingBottom: '100px'}} className='section-gray-loftyapps'>
          <Container maxWidth="xl" className='padding-container-loftyapps'>
            <div className='padding-container-loftyapps' style={{background: '#29B2EF', height: '2px', marginTop: '10px', marginBottom: '75px'}} />
            <h2 style={{textAlign: 'center'}} className='title1-loftyapps' data-aos="fade-up" data-aos-duration="1000">
              Exportación De Código
            </h2>
            <Grid container spacing={4}>
              {
              listExportCode.map((item) => (
                <Grid item xl={3} lg={3} md={6} sm={12} xs={12} data-aos="fade-up" data-aos-duration="1000">
                  <CardExportCode iconImg={item.iconImg} name={item.name} price={item.price} />
                </Grid>
              ))
              }
            </Grid>
          </Container>
        </section>
        <section style={{background: '#16579F'}}>
          <Container style={{background: '#16579F'}} maxWidth="xl" className='padding-container-loftyapps-section-consult'>
            <ConsultSection history={history} />
          </Container>
        </section>
        <section className='section-white-loftyapps'>
          <Container maxWidth='xl' className='padding-container-loftyapps'>
          <div className='container-video-loftyapps'>
            <div>
              <div>
                <iframe
                  width="560" height="315"
                  src="https://www.youtube.com/embed/keCHJhT4zRA?si=Owmdb-uRMsZLlBTt"
                  title="YouTube video player"
                  frameBorder="0"
                  className='youtube-loftyapps'
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen>
                </iframe>
              </div>
            </div>
            <div className='visual-future-loftyapps' >
              <h2 style={{margin: 0}} className='title1-loftyapps' data-aos="fade-up" data-aos-duration="1000">
                Una Visión Al Futuro
              </h2>
              <p className='text-loftyapps' data-aos="fade-up" data-aos-duration="1000">
                  Te brindamos una plataforma que facilita la transformación de tus ideas en software de forma directa y efectiva
              </p>
              <div>
                <Button
                  onClick={() => {
                    window.location.href = 'https://platform.loftyapps.com'
                  }}
                  variant='contained'
                  data-aos="fade-up" data-aos-duration="1000"

                >
                  Ingresar
                </Button>
              </div>
            </div>
          </div>
          </Container>
        </section>
        <section className='section-gray-loftyapps section-client-loftyapps'>
          <Container maxWidth='xl' className='padding-container-loftyapps'>
            <h2 style={{textAlign: 'center'}} className='title1-loftyapps' data-aos="fade-up" data-aos-duration="1000">
                ¿Que Dicen Nuestros Clientes?
            </h2>
            <Grid className='container-cards-client-loftyapps' container spacing={4}>
              {
                listClients.map((client) => (
                  <Grid style={{display: 'flex', justifyContent: 'center'}} key={client.name} item md={6} xs={12} data-aos="fade-up" data-aos-duration="1000">
                    <Card className='card-client-loftyapps'>
                      <CardContent>
                        <p style={{fontSize: 18, lineHeight: 2}} className='text-loftyapps border-botton-client-loftyapps'>
                          {client.description}
                        </p>
                        <div className='info-client-card'>
                          <div>
                            <p className='text-loftyapps' style={{fontSize: 24, fontWeight: 600}}>
                              {client.name}
                            </p>
                            <p className='text-center'>
                              {client.position}
                            </p>
                          </div>
                          <div>
                            <Avatar alt={client.name} src={client.img} style={{width: 95, height: 95}} />
                          </div>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              }

            </Grid>
          </Container>

        </section>
        <section style={{ height: 200 }} className='section-white-loftyapps' />


        <section
          className='section-white-loftyapps'
          style={{
            backgroundColor: 'white',
            padding: '20px 0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }}
        >

          <Container
            maxWidth="xl"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '784px',
              height: '75px',
              marginTop: '125px',
              textAlign: 'center',
            }}
          >
            <Typography
              variant="h4"
              align="center"
              gutterBottom
              style={{
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: '40px',
                lineHeight: '46.88px',
                color: '#000',
                textAlign: 'center',
                marginBottom: '60px',
              }}
            >
              Programas Clave que Han Potenciado Nuestra Marca
            </Typography>
          </Container>
        </section>

        <section
          className='section-white-loftyapps'
          style={{
            position: 'relative',
            color: 'white',
            padding: '100px 0',
            overflow: 'hidden',
            backgroundImage: `url(${landingRocket})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
          }}
        >
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(32, 21, 73, 0.5)',
              zIndex: 1,
            }}
          />

          <Container
            maxWidth='xl'
            className='padding-container-loftyapps'
            style={{ position: 'relative', zIndex: 2 }}
          >
            <Grid container spacing={2} alignItems="center" justifyContent="center">
              <Grid item xs={12} md={3} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box
                  sx={{
                    width: '175px',
                    height: '210px',
                    backgroundColor: 'white',
                    borderRadius: '15px',
                    border: '1px solid #fff',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginRight: { md: '-260px', xs: '0', lg: '-20px', },
                    marginBottom: { xs: '20px', md: '30', lg: '0', },
                  }}
                >
                  <img
                    src={tech4DevLogo}
                    alt="Tech4Dev"
                    style={{
                      maxWidth: '100%',
                      maxHeight: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={3} container direction="column" alignItems="center">
                <Grid item style={{ marginBottom: '20px' }}>
                  <Box
                    sx={{
                      width: '175px',
                      height: '210px',
                      backgroundColor: 'white',
                      borderRadius: '15px',
                      border: '1px solid #fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: { lg: '-250px', xs: '0', md: '-110px' },
                      marginBottom: { xs: '20px', md: '30', lg: '0', },


                    }}
                  >
                    <img
                      src={hondurasDigitalChallengeLogo}
                      alt="Honduras Digital Challenge"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      width: '175px',
                      height: '210px',
                      backgroundColor: 'white',
                      borderRadius: '15px',
                      border: '1px solid #fff',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginLeft: { lg: '-250px', xs: '0', md: '-110px' },
                      marginTop: { xs: '20px', md: '500px', lg: '0', },


                    }}
                  >
                    <img
                      src={emprendeRocketLogo}
                      alt="Emprende Rocket"
                      style={{
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: isMobile ? 'center' : 'flex-start',
                  textAlign: isMobile ? 'center' : 'left',
                  maxWidth: '442px',
                  marginLeft: isMobile ? '0' : '20px',
                  marginTop: isMobile ? '20px' : '20px',
                }}
              >
                <Typography
                  variant="h3"
                  style={{
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    fontSize: isMobile ? '28px' : '36px',
                  }}
                  gutterBottom
                >
                  Gracias por ser parte de nuestra historia
                </Typography>
                <Typography
                  variant="body1"
                  style={{
                    marginTop: '20px',
                    lineHeight: 1.5,
                    fontSize: isMobile ? '16px' : '18px',
                    color: '#D3D3D3',
                  }}
                >
                  Estos programas han sido fundamentales en nuestro crecimiento, brindándonos su respaldo y confianza para seguir innovando y ofreciendo lo mejor a nuestros clientes.
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default NewHome;